export default {
  DO_HAVE_BLEND_ACC: 'Do you have a BLEND account?',
  FORGOT: 'Forgot Your Password?',
  NO_ACC: "Don't have an account?",
  HAVE_ACC: 'Yes',
  HAVE_NOT_ACC: 'No',
  REG: 'Register',
  META_TITLE: 'SIGN IN - Professional Online Translation App | BLEND',
  META_TYPE: 'description',
  META_CONTENT:
    'Sign in to your BLEND account. BLEND provides professional localization solutions and fast online translation services in more than 120 languages.',
};
