'use client';

import {FC, useEffect, useMemo, useState} from 'react';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import {useSearchParams} from 'next/navigation';
import queryString, {ParsedQuery} from 'query-string';
import styled from 'styled-components';

import AuthSocial from '@/src/components/AuthModal/AuthSocial';
import LoginForm from '@/src/components/AuthModal/Login/components/Form';
import BaseButton from '@/src/components/BaseComponents/BaseButton';
import BaseLink from '@/src/components/BaseComponents/BaseLink';
import PageHeader from '@/src/components/PageHeader';
import useConfig from '@/src/hooks/useConfig';
import {useSession} from '@/src/hooks/useSession';
import Consts from '@/src/lib/AuthorizationConsts';
import {isEmailValid} from '@/src/lib/RegistrationUtils';
import {useGetUserDataQuery} from '@/src/lib/store/stores/api';
import {isXSS} from '@/src/utils/xss';
import {font_large} from '@/theme/fonts';

const {DO_HAVE_BLEND_ACC, FORGOT, NO_ACC, HAVE_ACC, HAVE_NOT_ACC, REG} = Consts;

const AuthView: FC = () => {
  const config = useConfig();
  const session = useSession();
  const query = useSearchParams();
  const search = query?.get('search') || '';
  const [email, setCurrentEmail] = useState<string>('');
  const [hasBlendAccount, setHasBlendAccount] = useState<boolean | null>(null);

  const queryParams: ParsedQuery<string> = useMemo(
    () => queryString.parse(search ? search.replace(/[+]/g, '%2B') : ''),
    [search]
  );

  useEffect(() => {
    queryParams.mail && isEmailValid(String(queryParams.mail)) && setCurrentEmail(String(queryParams.mail));
  }, [queryParams]);

  useEffect(() => {
    setHasBlendAccount(!(session?.snData && !session?.snData?.emailExists));
  }, [session?.snData]);

  return (
    <PageContainer container>
      <PageHeader title="Sign In" icon={<PersonOutlineOutlinedIcon fontSize="large" />} />
      <Grid style={{marginTop: 10}} container spacing={2}>
        <Grid size={{xs: 12}}>
          <FormWrapper elevation={3}>
            <PageContent>
              <Form>
                {config?.socialNetwork && <AuthSocial onCancelSN={() => setHasBlendAccount(true)} />}
                {hasBlendAccount || hasBlendAccount === null ? (
                  <>
                    <LoginForm emailUpdatedHandler={setCurrentEmail} agreeTermsValue emailParam={email} />

                    <ForgotPass>
                      <BaseLink data-qa-auto="forgotpass" href={`/auth/resetPassword${email ? '?email=' + email : ''}`}>
                        {FORGOT}
                      </BaseLink>
                    </ForgotPass>
                    <Account>
                      {NO_ACC}
                      <RegisterLink data-qa-auto="register-button" href="/auth/register">
                        {REG}
                      </RegisterLink>
                    </Account>
                  </>
                ) : (
                  <>
                    <Header>{DO_HAVE_BLEND_ACC}</Header>
                    <HasAccount>
                      <BaseButton data-qa-auto="has-blend-button" onClick={() => setHasBlendAccount(true)}>
                        {HAVE_ACC}
                      </BaseButton>
                      <BaseButton data-qa-auto="no-blend-button" href="/auth/register">
                        {HAVE_NOT_ACC}
                      </BaseButton>
                    </HasAccount>
                  </>
                )}
              </Form>
            </PageContent>
          </FormWrapper>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

const AuthorizationView: FC = () => {
  const searchParams = useSearchParams();
  const {isLoading, data: userData} = useGetUserDataQuery();

  const isLoggedIn: boolean = useMemo(() => !!userData?.id, [userData?.id]);

  const returnFromUrl = useMemo(() => searchParams?.get('return') || '', [searchParams]);

  useEffect(() => {
    if (isLoggedIn) {
      window.location.href = returnFromUrl && !isXSS(returnFromUrl) ? returnFromUrl : '/cpanel';
    }
  }, [isLoggedIn, returnFromUrl]);

  return isLoggedIn && !isLoading ? null : <AuthView />;
};

export default AuthorizationView;

const PageContainer = styled(Grid)`
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f9;
  min-height: calc(100vh - 134px);
  display: block !important;
`;

const PageContent = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const Header = styled.h3`
  width: 100%;
  text-align: center;
`;

const HasAccount = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  & > button,
  > a {
    width: 30%;
    background-color: ${({theme}) => theme.colors.orange090};
    box-shadow: 0 2px 4px #00000014;
    color: ${({theme}) => theme.colors.grey000};
    &:hover {
      background-color: ${({theme}) => theme.colors.orange100};
      box-shadow: none;
      color: ${({theme}) => theme.colors.grey000};
    }
  }
`;

const Form = styled.div`
  max-width: 500px;
  width: 70%;
  margin: 20px auto;
  background-color: transparent;
  transition: all 0.2s ease;
`;

const FormWrapper = styled(Paper)`
  &.MuiPaper-root {
    min-height: 369px;
  }
`;

const Account = styled.div`
  ${font_large};
  display: flex;
  color: ${({theme}) => theme.colors.grey100};
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  @media ${({theme}) => theme.breakpoints.maxSm} {
    flex-direction: column;
    text-align: center;
  }
`;

const ForgotPass = styled.div`
  ${font_large};
  color: ${({theme}) => theme.colors.blue100};
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${({theme}) => theme.breakpoints.maxSm} {
    text-align: center;
  }
`;

const RegisterLink = styled(BaseLink)`
  &.MuiTypography-root {
    color: ${({theme}) => theme.colors.blue100};
    border: 1px solid ${({theme}) => theme.colors.blue100};
    border-radius: 20px;
    padding: 8px 16px;
    margin-left: 16px;
    width: 90px;

    &:hover {
      text-decoration: none;
      background: ${({theme}) => theme.colors.grey030};
    }
  }
`;
