import {FC, ReactNode} from 'react';

import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';

import User from '@/assets/icons_refactor/Wizard/user.svg';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import {boldWeight, font_huge} from '@/theme/fonts';

interface Props {
  icon?: ReactNode;
  title: string;
  subtitle?: string;
  additionalComponent?: ReactNode;
  hideTitleComponent?: boolean;
}

const PageHeader: FC<Props> = ({title, subtitle, additionalComponent, hideTitleComponent}) => (
  <Grid container>
    <Grid size={{xs: 12}}>
      <Paper elevation={3}>
        <Grid container justifyContent="space-between" alignItems="center">
          {!hideTitleComponent && (
            <Grid>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Icon icon={User} boxW={30} boxH={30} />
                </Grid>
                <Grid>
                  <Title data-qa-auto="page_titles">{title}</Title>
                  {subtitle && <Subtitle>{subtitle}</Subtitle>}
                </Grid>
              </Grid>
            </Grid>
          )}
          {additionalComponent && <>{additionalComponent}</>}
        </Grid>
      </Paper>
    </Grid>
  </Grid>
);

const Icon = styled(BaseIcon)`
  width: 30px;
  height: 30px;
  fill: ${({theme}) => theme.colors.grey100};
`;

const Title = styled.h4`
  font-size: 24px;
  margin-bottom: 0;
`;

const Subtitle = styled.div`
  ${font_huge};
  ${boldWeight};
`;

export default PageHeader;
